
import { defineComponent } from "vue";
import { oneOf, required } from "@/uikit/_utils/assist";

export interface TIcon {
  name: string;
  type?: "fas" | "fal" | "fad" | "far";
}

export default defineComponent({
  name: "Icon",
  props: {
    fontSize: {
      type: String,
      default: "15px"
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "fas"
    }
  },
  setup(props) {
    const validate =
      oneOf("<Icon3> type", props.type, ["", "fas", "fal", "fad", "far"]) &&
      required("<Icon3> name", props.name);
    if (!validate) return;
    const type = props.type || "fas";
    const className = `${type} fa-${props.name}`;
    return {
      className
    };
  }
});
